.container {
    display: flex;
    height: 100%;
  }

  .reactFlow {
    background-color: #5f5d72;
  }

  .node {
    background-color: #5f5d72;
    color: #fff;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.533) 0px 0px 9px 0px;
    padding: 15px;
    border-radius: 12px;
  }

  .nodeDropzone {
    background-color: #ff0072;
    box-shadow: 0 0 9px 0 #ff0072;
  }

  .handle {
    pointer-events: none;
    opacity: 0;
  }

  .sidebar {
    color: white;
    top: 5px;
    left: 5px;
    z-index: 1000;
    padding: 10px;
    border-right: 1px solid #222138;
    width: 120px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: #5f5d72;
  }

  .sidebarLabel {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .sidebarNode {
    background: #222138;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .sidebarNode:hover {
    box-shadow: rgba(0, 0, 0, 0.533) 0px 0px 9px 0px;
  }


  .searchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #5f5d72;
  }

  .learnText {
    font-family: 'Trebuchet MS';
    font-size: 35px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 16px;
  }

  .searchForm {
    display: flex;
  }

  .searchInput {
    font-family: 'Trebuchet MS';
    padding: 6px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    outline: none;
  }

  .searchButton {
    font-family: 'Trebuchet MS';
    padding: 6px 12px;
    font-size: 20px;
    border: none;
    background-color: #222138;
    color: white;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
  }

  .searchButton:hover {
    background-color: #ff0072;
  }

  .nodeHover {
    background-color: #222138;
  }


  .resourceContainer {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }


  .sourceHandle {
    width: 30px;
    height: 14px;
    border-radius: 3px;
    background-color: #000000;
  }



